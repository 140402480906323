import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t } = useTranslation();
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqData = [
    { question: 'faq1Question', answer: 'faq1Answer' },
    { question: 'faq2Question', answer: 'faq2Answer' },
    { question: 'faq3Question', answer: 'faq3Answer' },
    { question: 'faq4Question', answer: 'faq4Answer' },
    { question: 'faq5Question', answer: 'faq5Answer' },
  ];

  return (
    <div className="faq-page">
      <h1 className="main-heading">{t('faqTitle')}</h1>
      <div>
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className="faq-question"
              onClick={() => toggleQuestion(index)}
            >
              {t(faq.question)}
              <span>{openQuestion === index ? '−' : '+'}</span>
            </button>
            {openQuestion === index && (
              <div className="faq-answer">{t(faq.answer)}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;