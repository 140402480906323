import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

const formatContent = (content) => {
  if (!content) return null;

  // Split content into paragraphs
  const paragraphs = content.split('\n\n');

  return paragraphs.map((paragraph, index) => {
    // Replace bold text
    paragraph = paragraph.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    
    // Replace italic text
    paragraph = paragraph.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Check if the paragraph is a list item
    if (paragraph.startsWith('- ')) {
      return (
        <li key={index} dangerouslySetInnerHTML={{ __html: paragraph.substring(2) }} />
      );
    }

    // Check if the paragraph is a numbered list item
    if (/^\d+\.\s/.test(paragraph)) {
      return (
        <li key={index} dangerouslySetInnerHTML={{ __html: paragraph.replace(/^\d+\.\s/, '') }} />
      );
    }

    // Regular paragraph
    return <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />;
  });
};

const BlogPostPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  
  const blogPosts = t('blogPosts', { returnObjects: true });
  const post = blogPosts.find(p => p.id === id);

  if (!post) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="blog-post-page content-wrapper">
      <h1 className="main-heading">{post.title}</h1>
      <div className="blog-post-content">
        {formatContent(post.content)}
      </div>
      <Link to="/blog" className="back-to-blog">{t('backToBlog')}</Link>
    </div>
  );
};

export default BlogPostPage;