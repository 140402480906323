import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <div className="terms-of-use">
      <Helmet>
        <title>{t('termsOfUseTitle')} | IBAN Verifica</title>
        <meta name="description" content={t('termsOfUseDescription')} />
      </Helmet>
      <h1>{t('termsOfUseTitle')}</h1>
      <p>{t('termsOfUseLastUpdated', { date: 'October 15, 2024' })}</p>
      
      <section>
        <h2>{t('acceptanceOfTerms')}</h2>
        <p>{t('acceptanceOfTermsContent')}</p>
      </section>
      
      <section>
        <h2>{t('useOfService')}</h2>
        <p>{t('useOfServiceContent')}</p>
      </section>
      
      <section>
        <h2>{t('intellectualProperty')}</h2>
        <p>{t('intellectualPropertyContent')}</p>
      </section>
      
      <section>
        <h2>{t('limitationOfLiability')}</h2>
        <p>{t('limitationOfLiabilityContent')}</p>
      </section>
      
      <section>
        <h2>{t('governingLaw')}</h2>
        <p>{t('governingLawContent')}</p>
      </section>
      
      <section>
        <h2>{t('changestoTerms')}</h2>
        <p>{t('changestoTermsContent')}</p>
      </section>
    </div>
  );
};

export default TermsOfUse;