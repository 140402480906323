import React from 'react';
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t } = useTranslation();

  const featuresList = [
    { title: 'feature1Title', description: 'feature1Description' },
    { title: 'feature2Title', description: 'feature2Description' },
    { title: 'feature3Title', description: 'feature3Description' },
    { title: 'feature4Title', description: 'feature4Description' },
  ];

  return (
    <div className="features-page content-wrapper">
      <h1 className="main-heading">{t('featuresTitle')}</h1>
      <div className="features-grid">
        {featuresList.map((feature, index) => (
          <div key={index} className="feature-item">
            <h2>{t(feature.title)}</h2>
            <p>{t(feature.description)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;