import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const IBANCodesPage = () => {
  const { t } = useTranslation();

  return (
    <div className="iban-codes-page">
      <Helmet>
        <title>{t('ibanCodesPageTitle')}</title>
        <meta name="description" content={t('ibanCodesMetaDescription')} />
      </Helmet>
      <h1>{t('ibanCodesHeading')}</h1>
      <p>{t('ibanCodesIntro')}</p>
      <h2>{t('commonIBANFormats')}</h2>
      <ul>
        <li><strong>Germany:</strong> DE00 0000 0000 0000 0000 00</li>
        <li><strong>France:</strong> FR00 0000 0000 0000 0000 0000 000</li>
        <li><strong>Italy:</strong> IT00 X000 0000 0000 0000 0000 000</li>
        <li><strong>Spain:</strong> ES00 0000 0000 0000 0000 0000</li>
        <li><strong>United Kingdom:</strong> GB00 XXXX 0000 0000 0000 00</li>
      </ul>
      <p>{t('ibanCodesExplanation')}</p>
    </div>
  );
};

export default IBANCodesPage;