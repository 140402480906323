import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <Helmet>
        <title>{t('privacyPolicyTitle')} | IBAN Verifica</title>
        <meta name="description" content={t('privacyPolicyDescription')} />
      </Helmet>
      <h1>{t('privacyPolicyTitle')}</h1>
      <p>{t('privacyPolicyLastUpdated', { date: 'October 15, 2024' })}</p>
      
      <section>
        <h2>{t('informationWeCollect')}</h2>
        <p>{t('informationWeCollectContent')}</p>
      </section>
      
      <section>
        <h2>{t('howWeUseInformation')}</h2>
        <p>{t('howWeUseInformationContent')}</p>
      </section>
      
      <section>
        <h2>{t('dataProtection')}</h2>
        <p>{t('dataProtectionContent')}</p>
      </section>
      
      <section>
        <h2>{t('yourRights')}</h2>
        <p>{t('yourRightsContent')}</p>
      </section>
      
      <section>
        <h2>{t('contactUs')}</h2>
        <p>{t('contactUsContent')}</p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;