import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="about-page content-wrapper">
      <h1 className="main-heading">{t('aboutTitle')}</h1>
      <div className="about-content">
        <p>{t('aboutDescription')}</p>
        <h2>{t('ourMission')}</h2>
        <p>{t('missionDescription')}</p>
        <h2>{t('whyUseOurService')}</h2>
        <ul>
          <li>{t('feature1')}</li>
          <li>{t('feature2')}</li>
          <li>{t('feature3')}</li>
          <li>{t('feature4')}</li>
        </ul>
      </div>
    </div>
  );
};

export default About;