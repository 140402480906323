import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation();

  return (
    <header>
      <nav>
        <ul>
          <li><Link to="/">{t('home')}</Link></li>
          <li><Link to="/about">{t('about')}</Link></li>
          <li><Link to="/features">{t('features')}</Link></li>
          <li><Link to="/faq">{t('faq')}</Link></li>
          <li><Link to="/blog">{t('blog')}</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;