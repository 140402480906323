import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { v4 as uuidv4 } from 'uuid';
import { encryptData } from "../utils/crypto";
import ReactGA from 'react-ga4';

const particlesOptions = {
  background: {
    color: {
      value: "#293241",
    },
  },
  fpsLimit: 60,
  particles: {
    color: {
      value: "#ffffff",
    },
    links: {
      color: "#ffffff",
      distance: 150,
      enable: true,
      opacity: 0.2,
      width: 1,
    },
    move: {
      direction: "none",
      enable: true,
      outModes: {
        default: "bounce",
      },
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        area: 800,
      },
      value: 80,
    },
    opacity: {
      value: 0.2,
    },
    shape: {
      type: "circle",
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  interactivity: {
    detectsOn: "window",
    events: {
      onHover: {
        enable: true,
        mode: "repulse",
      },
      onClick: {
        enable: true,
        mode: "push",
      },
    },
    modes: {
      repulse: {
        distance: 100,
        duration: 0.4,
      },
      push: {
        quantity: 4,
      },
    },
  },
  detectRetina: true,
};

const IBANChecker = () => {
  const [iban, setIban] = useState('');
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const validateIBAN = async (ibanToValidate) => {
    setLoading(true);
    
    ReactGA.event({
      category: 'API',
      action: 'IBAN Validation API Call',
      label: 'IBAN Checker'
    });

    try {
      const nonce = uuidv4();
      const dataToEncrypt = {
        apiKey: API_KEY,
        nonce: nonce
      };
      // console.log('API Key:', API_KEY);
      // console.log('Encrypting API key and nonce...');
      const encryptedData = encryptData(JSON.stringify(dataToEncrypt));
      // console.log('Encrypted Data:', encryptedData);

      if (!encryptedData) {
        throw new Error('Failed to encrypt data');
      }

      // console.log('Sending request to:', `${API_URL}/api/validate-iban`);
      const response = await fetch(`/api/validate-iban`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Encrypted-Data': encryptedData
        },
        body: JSON.stringify({ iban: ibanToValidate, nonce }),
      });

      // console.log('Response status:', response.status);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log('Response data:', data);
      setResult(data);

      ReactGA.event({
        category: 'API',
        action: 'IBAN Validation Result',
        label: data.isValid ? 'Valid IBAN' : 'Invalid IBAN'
      });
    } catch (error) {
      // console.error('Error validating IBAN:', error);
      setResult({ isValid: false, message: `${t('errorValidating')}: ${error.message}` });

      ReactGA.event({
        category: 'API',
        action: 'IBAN Validation Error',
        label: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateIBAN(iban);
  };

  const handleExampleClick = (exampleIBAN) => {
    setIban(exampleIBAN);
    validateIBAN(exampleIBAN);
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "IBAN Verifica - IBAN Verification Tool",
    "description": "Verify and validate International Bank Account Numbers (IBAN) instantly. Check IBAN codes and confirm IBAN account numbers online.",
    "url": "https://www.ibanverifica.com",
    "applicationCategory": "FinanceApplication",
    "operatingSystem": "Web"
  };

  return (
    <>
      <Helmet>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
        <meta name="keywords" content={t('metaKeywords')} />
        <link rel="canonical" href="https://www.ibanverifica.com" />
        <meta property="og:title" content={t('metaTitle')} />
        <meta property="og:description" content={t('metaDescription')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ibanverifica.com" />
        <meta property="og:image" content="https://www.ibanverifica.com/og-image.jpg" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="iban-checker-container">
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={particlesOptions}
          className="particles-background"
        />
        <div className="content-wrapper">
          <h1 className="main-heading">{t('title')}</h1>
          <p className="subtitle">{t('subtitle')}</p>
          
          <div className="iban-checker-form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="iban-input">{t('enterIBAN')}</label>
              <input
                id="iban-input"
                type="text"
                value={iban}
                onChange={(e) => setIban(e.target.value)}
                placeholder="e.g., GB12ABCD12345612345678"
              />
              <button type="submit" disabled={loading}>
                {loading ? t('checking') : t('verifyIBAN')}
              </button>
            </form>
            
            <div className="example-ibans">
              <p>{t('orTryExamples')}:</p>
              <button onClick={() => handleExampleClick('IT60X0542811101000000123456')} disabled={loading}>
              IT60X0542811101000000123456
              </button>
              <button onClick={() => handleExampleClick('DE89370400440532013000')} disabled={loading}>
                DE89370400440532013000
              </button>
            </div>
            
            {result && (
              <div className={`result ${result.isValid ? 'valid' : 'invalid'}`}>
                <h2>{result.isValid ? t('validIBAN') : t('invalidIBAN')}</h2>
                <p>{result.message}</p>
                {result.isValid && result.details && (
                  <table>
                    <tbody>
                      <tr><th>{t('countryCode')}</th><td>{result.details.countryCode}</td></tr>
                      <tr><th>{t('checkDigits')}</th><td>{result.details.checkDigits}</td></tr>
                      <tr><th>{t('bban')}</th><td>{result.details.bban}</td></tr>
                      <tr><th>{t('accountNumber')}</th><td>{result.details.accountNumber}</td></tr>
                      <tr><th>{t('bankIdentifier')}</th><td>{result.details.bankIdentifier}</td></tr>
                      <tr><th>{t('branchIdentifier')}</th><td>{result.details.branchIdentifier}</td></tr>
                      <tr><th>{t('bic')}</th><td>{result.details.bic}</td></tr>
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>

          <section className="iban-info">
            <h2>{t('whatIsIBAN')}</h2>
            <p>{t('ibanExplanation')}</p>
            <h3>{t('ibanComponents')}</h3>
            <ul>
              <li>{t('countryCodeExplanation')}</li>
              <li>{t('checkDigitsExplanation')}</li>
              <li>{t('bbanExplanation')}</li>
            </ul>
          </section>
        </div>
      </div>
    </>
  );
};

export default IBANChecker;