import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Blog = () => {
  const { t } = useTranslation();

  // Use the new blogPosts structure from translations
  const blogPosts = t('blogPosts', { returnObjects: true });

  return (
    <div className="blog-page content-wrapper">
      <h1 className="main-heading">{t('blogTitle')}</h1>
      <div className="blog-posts">
        {blogPosts.map((post) => (
          <article key={post.id} className="blog-post-preview">
            <h2>{post.title}</h2>
            <p>{post.summary}</p>
            <Link to={`/blog/${post.id}`} className="read-more">
              {t('readMore')}
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Blog;