import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="app-footer">
      <p>{t('footerText', { year: new Date().getFullYear() })}</p>
      <nav>
        <Link to="/privacy">{t('privacyPolicy')}</Link>
        <Link to="/terms">{t('termsOfUse')}</Link>
      </nav>
    </footer>
  );
};

export default Footer;