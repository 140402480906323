import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Helmet } from 'react-helmet';
import i18n from './i18n';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import FAQPage from './pages/FAQPage';
import FeaturesPage from './pages/FeaturesPage';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import IBANCodesPage from './pages/IBANCodesPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import LanguageSwitcher from './components/LanguageSwitcher';
import './index.css';
import ReactGA from 'react-ga4';

const initializeGA = () => {
  ReactGA.initialize('G-SGEB6NYNN3');
};

function PageViewTracker() {
  const location = useLocation();
 
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <PageViewTracker />
        <div className="app">
          <Helmet>
            <html lang="en" />
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="apple-touch-icon" href="/logo192.png" />
            <link rel="manifest" href="/manifest.json" />
          </Helmet>
          <Header />
          <LanguageSwitcher />
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/features" element={<FeaturesPage />} />
                <Route path="/blog/:id" element={<BlogPostPage />} />
                <Route path="/blog" element={<BlogPage />} />
                <Route path="/iban-codes" element={<IBANCodesPage />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsOfUse />} />
              </Routes>
            </Suspense>
          </main>
          <Footer />
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;